body {
  margin: 0;
  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overscroll-behavior-y: none;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

button {
  background: transparent;
  border: none;
  outline: none;
  font-family: 'Montserrat';
}

input:-internal-autofill-selected {
  background-color: #ffffff !important;
}
/* link style */
a {
  font-weight: 500;
  font-size: 16px;
  color: #1fd7ae;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  box-shadow: 0 0 0 30px #ffffff inset !important;
}
